/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 13, 2018 */

@font-face {
	font-family: 'Elliot Sans';
	src: url('elliotsans-light.woff2') format('woff2'),
		 url('elliotsans-light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Elliot Sans';
	src: url('elliotsans-lightitalic.woff2') format('woff2'),
		 url('elliotsans-lightitalic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Elliot Sans';
	src: url('elliotsans-regular.woff2') format('woff2'),
		 url('elliotsans-regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Elliot Sans';
	src: url('elliotsans-regularitalic.woff2') format('woff2'),
		 url('elliotsans-regularitalic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Elliot Sans';
	src: url('elliotsans-medium.woff2') format('woff2'),
		 url('elliotsans-medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Elliot Sans';
	src: url('elliotsans-mediumitalic.woff2') format('woff2'),
		 url('elliotsans-mediumitalic.woff') format('woff');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Elliot Sans';
	src: url('elliotsans-bold.woff2') format('woff2'),
		 url('elliotsans-bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Elliot Sans';
	src: url('elliotsans-bolditalic.woff2') format('woff2'),
		 url('elliotsans-bolditalic.woff') format('woff');
	font-weight: bold;
	font-style: italic;
}
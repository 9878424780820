/*!
 * Bootstrap Reboot v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */

*, *::before, *::after {
	box-sizing: border-box;
}

html {
	font-family: sans-serif;
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-ms-overflow-style: scrollbar;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
	width: device-width;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
	display: block;
}

[tabindex="-1"]:focus {
	outline: 0;
}

h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	margin-bottom: 0.5rem;
}

p {
	margin-top: 0;
	margin-bottom: 1rem;
}

address {
	margin-bottom: 1rem;
	font-style: normal;
	line-height: inherit;
}

ol, ul, dl {
	margin-top: 0;
	margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
	margin-bottom: 0;
}

b, strong {
	font-weight: bolder;
}

small {
	font-size: 80%;
}

sub, sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline;
}

sub {
	bottom: -.25em;
}

sup {
	top: -.5em;
}

a {
	color: inherit;
	text-decoration: none;
	background-color: transparent;
	-webkit-text-decoration-skip: objects;
}

a:hover {
	color: inherit;
	text-decoration: underline;
}

svg {
	overflow: hidden;
	vertical-align: middle;
}

table {
	border-collapse: collapse;
}

th {
	text-align: inherit;
}

label {
	display: inline-block;
	margin-bottom: 0.5rem;
}

button {
	border-radius: 0;
}

button:focus {
	outline: 1px dotted;
	outline: 5px auto -webkit-focus-ring-color;
}

input, button, select, optgroup, textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

button, input {
	overflow: visible;
}

button, select {
	text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
	-webkit-appearance: button;
	appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
	padding: 0;
	border-style: none;
}

input[type="radio"], input[type="checkbox"] {
	box-sizing: border-box;
	padding: 0;
}

[hidden] {
	display: none;
}

.cla-hidden {
	display: none;
	visibility: hidden;
	width: 0;
	height: 0;
	font-size: 0;
	overflow: hidden;
	pointer-events: none;
}

.cla-white-overlay {
	background: rgba(255, 255, 255, 0.8);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 51;
}

.cla-heigher-z-idex {
	z-index: 101;
}

/*--------  reset css end  --------*/

/*-------- global css ---------*/

img {
	max-width: 100%;
	height: auto;
	vertical-align: middle;
	border-style: none;
}

hr {
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}

.list-unstyled {
	padding-left: 0;
	list-style: none;
}

.list-inline {
	padding-left: 0;
	list-style: none;
}

.list-inline-item {
	display: inline-block;
}

.list-inline-item:not(:last-child) {
	margin-right: 0.5rem;
}

.table {
	width: 100%;
	margin-bottom: 1rem;
	background-color: transparent;
}

.table th, .table td {
	padding: 0.75rem;
	vertical-align: top;
	border-top: 1px solid #dee2e6;
}

.table-responsive {
	display: block;
	width: 100%;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
}

.w-100 {
	width: 100%;
}

.w-auto {
	width: auto;
}

.h-100 {
	height: 100%;
}

.h-auto {
	height: auto;
}

.mw-100 {
	max-width: 100%;
}

/*------ form css -----*/

.form-control:focus {
	color: #495057;
	background-color: #fff;
	border-color: #80bdff;
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::-webkit-input-placeholder {
	color: #6c757d;
	opacity: 1;
}

.form-control::-moz-placeholder {
	color: #6c757d;
	opacity: 1;
}

.form-control:-ms-input-placeholder {
	color: #6c757d;
	opacity: 1;
}

.form-control::-ms-input-placeholder {
	color: #6c757d;
	opacity: 1;
}

.form-control::placeholder {
	color: #6c757d;
	opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
	background-color: #e9ecef;
	opacity: 1;
}

textarea.form-control {
	height: auto;
}

.form-group {
	margin-bottom: 1rem;
}

/*------ utilities  --------*/

.align-middle {
	vertical-align: middle;
}

.border {
	border: 1px solid #dee2e6;
}

.border-top {
	border-top: 1px solid #dee2e6;
}

.border-right {
	border-right: 1px solid #dee2e6;
}

.border-bottom {
	border-bottom: 1px solid #dee2e6;
}

.border-left {
	border-left: 1px solid #dee2e6;
}

.rounded-0 {
	border-radius: 0;
}

.clearfix::after {
	display: block;
	clear: both;
	content: "";
}

.d-none {
	display: none;
}

.d-inline-block {
	display: inline-block;
}

.d-block {
	display: block;
}

.d-flex {
	display: -ms-flexbox !important;
	display: flex !important;
}

@media (min-width: 576px) {
	.d-sm-none {
		display: none;
	}
	.d-sm-inline-block {
		display: inline-block;
	}
	.d-sm-block {
		display: block;
	}
	.d-sm-flex {
		display: -ms-flexbox;
		display: flex;
	}
}

@media (min-width: 768px) {
	.d-md-none {
		display: none;
	}
	.d-md-inline-block {
		display: inline-block;
	}
	.d-md-block {
		display: block;
	}
	.d-md-flex {
		display: -ms-flexbox;
		display: flex;
	}
}

@media (min-width: 992px) {
	.d-lg-none {
		display: none;
	}
	.d-lg-inline-block {
		display: inline-block;
	}
	.d-lg-block {
		display: block;
	}
	.d-lg-flex {
		display: -ms-flexbox;
		display: flex;
	}
}

@media (min-width: 1200px) {
	.d-xl-none {
		display: none;
	}
	.d-xl-inline-block {
		display: inline-block;
	}
	.d-xl-block {
		display: block;
	}
	.d-xl-flex {
		display: -ms-flexbox;
		display: flex;
	}
}

.flex-wrap {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.flex-grow-1 {
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.justify-content-end {
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.justify-content-center {
	-ms-flex-pack: center;
	justify-content: center;
}

.justify-content-between {
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.justify-content-around {
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

.align-items-start {
	-ms-flex-align: start;
	align-items: flex-start;
}

.align-items-end {
	-ms-flex-align: end;
	align-items: flex-end;
}

.align-items-center {
	-ms-flex-align: center;
	align-items: center;
}

.align-self-end {
	-ms-flex-item-align: end;
	align-self: flex-end;
}

.align-self-center {
	-ms-flex-item-align: center;
	align-self: center;
}

@media (min-width: 576px) {
	.justify-content-sm-end {
		-ms-flex-pack: end;
		justify-content: flex-end;
	}
}

/*---- position  css ----*/

.position-static {
	position: static;
}

.position-relative {
	position: relative;
}

.position-sticky {
	position: -webkit-sticky;
	position: sticky;
}

/* position css end */

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	overflow: visible;
	clip: auto;
	white-space: normal;
}

/*------- spacing css ------------*/

.m-0 {
	margin: 0;
}

.mt-0, .my-0 {
	margin-top: 0;
}

.mr-0, .mx-0 {
	margin-right: 0;
}

.mb-0, .my-0 {
	margin-bottom: 0;
}

.ml-0, .mx-0 {
	margin-left: 0;
}

.m-1 {
	margin: 0.25rem;
}

.mt-1, .my-1 {
	margin-top: 0.25rem;
}

.mr-1, .mx-1 {
	margin-right: 0.25rem;
}

.mb-1, .my-1 {
	margin-bottom: 0.25rem;
}

.ml-1, .mx-1 {
	margin-left: 0.25rem;
}

.m-2 {
	margin: 0.5rem;
}

.mt-2, .my-2 {
	margin-top: 0.5rem;
}

.mr-2, .mx-2 {
	margin-right: 0.5rem;
}

.mb-2, .my-2 {
	margin-bottom: 0.5rem;
}

.ml-2, .mx-2 {
	margin-left: 0.5rem;
}

.m-3 {
	margin: 1rem;
}

.mt-3, .my-3 {
	margin-top: 1rem;
}

.mr-3, .mx-3 {
	margin-right: 1rem;
}

.mb-3, .my-3 {
	margin-bottom: 1rem;
}

.ml-3, .mx-3 {
	margin-left: 1rem;
}

.m-4 {
	margin: 1.5rem;
}

.mt-4, .my-4 {
	margin-top: 1.5rem;
}

.mr-4, .mx-4 {
	margin-right: 1.5rem;
}

.mb-4, .my-4 {
	margin-bottom: 1.5rem;
}

.ml-4, .mx-4 {
	margin-left: 1.5rem;
}

.m-5 {
	margin: 3rem;
}

.mt-5, .my-5 {
	margin-top: 3rem;
}

.mr-5, .mx-5 {
	margin-right: 3rem;
}

.mb-5, .my-5 {
	margin-bottom: 3rem;
}

.ml-5, .mx-5 {
	margin-left: 3rem;
}

.p-0 {
	padding: 0;
}

.pt-0, .py-0 {
	padding-top: 0;
}

.pr-0, .px-0 {
	padding-right: 0;
}

.pb-0, .py-0 {
	padding-bottom: 0;
}

.pl-0, .px-0 {
	padding-left: 0;
}

.p-1 {
	padding: 0.25rem;
}

.pt-1, .py-1 {
	padding-top: 0.25rem;
}

.pr-1, .px-1 {
	padding-right: 0.25rem;
}

.pb-1, .py-1 {
	padding-bottom: 0.25rem;
}

.pl-1, .px-1 {
	padding-left: 0.25rem;
}

.p-2 {
	padding: 0.5rem;
}

.pt-2, .py-2 {
	padding-top: 0.5rem;
}

.pr-2, .px-2 {
	padding-right: 0.5rem;
}

.pb-2, .py-2 {
	padding-bottom: 0.5rem;
}

.pl-2, .px-2 {
	padding-left: 0.5rem;
}

.p-3 {
	padding: 1rem;
}

.pt-3, .py-3 {
	padding-top: 1rem;
}

.pr-3, .px-3 {
	padding-right: 1rem;
}

.pb-3, .py-3 {
	padding-bottom: 1rem;
}

.pl-3, .px-3 {
	padding-left: 1rem;
}

.p-4 {
	padding: 1.5rem;
}

.pt-4, .py-4 {
	padding-top: 1.5rem;
}

.pr-4, .px-4 {
	padding-right: 1.5rem;
}

.pb-4, .py-4 {
	padding-bottom: 1.5rem;
}

.pl-4, .px-4 {
	padding-left: 1.5rem;
}

.p-5 {
	padding: 3rem;
}

.pt-5, .py-5 {
	padding-top: 3rem;
}

.pr-5, .px-5 {
	padding-right: 3rem;
}

.pb-5, .py-5 {
	padding-bottom: 3rem;
}

.pl-5, .px-5 {
	padding-left: 3rem;
}

.m-auto {
	margin: auto;
}

.mt-auto, .my-auto {
	margin-top: auto;
}

.mr-auto, .mx-auto {
	margin-right: auto;
}

.mb-auto, .my-auto {
	margin-bottom: auto;
}

.ml-auto, .mx-auto {
	margin-left: auto;
}

@media (min-width: 576px) {
	.m-sm-0 {
		margin: 0;
	}
	.mt-sm-0, .my-sm-0 {
		margin-top: 0;
	}
	.mr-sm-0, .mx-sm-0 {
		margin-right: 0;
	}
	.mb-sm-0, .my-sm-0 {
		margin-bottom: 0;
	}
	.ml-sm-0, .mx-sm-0 {
		margin-left: 0;
	}
	.m-sm-1 {
		margin: 0.25rem;
	}
	.mt-sm-1, .my-sm-1 {
		margin-top: 0.25rem;
	}
	.mr-sm-1, .mx-sm-1 {
		margin-right: 0.25rem;
	}
	.mb-sm-1, .my-sm-1 {
		margin-bottom: 0.25rem;
	}
	.ml-sm-1, .mx-sm-1 {
		margin-left: 0.25rem;
	}
	.m-sm-2 {
		margin: 0.5rem;
	}
	.mt-sm-2, .my-sm-2 {
		margin-top: 0.5rem;
	}
	.mr-sm-2, .mx-sm-2 {
		margin-right: 0.5rem;
	}
	.mb-sm-2, .my-sm-2 {
		margin-bottom: 0.5rem;
	}
	.ml-sm-2, .mx-sm-2 {
		margin-left: 0.5rem;
	}
	.m-sm-3 {
		margin: 1rem;
	}
	.mt-sm-3, .my-sm-3 {
		margin-top: 1rem;
	}
	.mr-sm-3, .mx-sm-3 {
		margin-right: 1rem;
	}
	.mb-sm-3, .my-sm-3 {
		margin-bottom: 1rem;
	}
	.ml-sm-3, .mx-sm-3 {
		margin-left: 1rem;
	}
	.m-sm-4 {
		margin: 1.5rem;
	}
	.mt-sm-4, .my-sm-4 {
		margin-top: 1.5rem;
	}
	.mr-sm-4, .mx-sm-4 {
		margin-right: 1.5rem;
	}
	.mb-sm-4, .my-sm-4 {
		margin-bottom: 1.5rem;
	}
	.ml-sm-4, .mx-sm-4 {
		margin-left: 1.5rem;
	}
	.m-sm-5 {
		margin: 3rem;
	}
	.mt-sm-5, .my-sm-5 {
		margin-top: 3rem;
	}
	.mr-sm-5, .mx-sm-5 {
		margin-right: 3rem;
	}
	.mb-sm-5, .my-sm-5 {
		margin-bottom: 3rem;
	}
	.ml-sm-5, .mx-sm-5 {
		margin-left: 3rem;
	}
	.p-sm-0 {
		padding: 0;
	}
	.pt-sm-0, .py-sm-0 {
		padding-top: 0;
	}
	.pr-sm-0, .px-sm-0 {
		padding-right: 0;
	}
	.pb-sm-0, .py-sm-0 {
		padding-bottom: 0;
	}
	.pl-sm-0, .px-sm-0 {
		padding-left: 0;
	}
	.p-sm-1 {
		padding: 0.25rem;
	}
	.pt-sm-1, .py-sm-1 {
		padding-top: 0.25rem;
	}
	.pr-sm-1, .px-sm-1 {
		padding-right: 0.25rem;
	}
	.pb-sm-1, .py-sm-1 {
		padding-bottom: 0.25rem;
	}
	.pl-sm-1, .px-sm-1 {
		padding-left: 0.25rem;
	}
	.p-sm-2 {
		padding: 0.5rem;
	}
	.pt-sm-2, .py-sm-2 {
		padding-top: 0.5rem;
	}
	.pr-sm-2, .px-sm-2 {
		padding-right: 0.5rem;
	}
	.pb-sm-2, .py-sm-2 {
		padding-bottom: 0.5rem;
	}
	.pl-sm-2, .px-sm-2 {
		padding-left: 0.5rem;
	}
	.p-sm-3 {
		padding: 1rem;
	}
	.pt-sm-3, .py-sm-3 {
		padding-top: 1rem;
	}
	.pr-sm-3, .px-sm-3 {
		padding-right: 1rem;
	}
	.pb-sm-3, .py-sm-3 {
		padding-bottom: 1rem;
	}
	.pl-sm-3, .px-sm-3 {
		padding-left: 1rem;
	}
	.p-sm-4 {
		padding: 1.5rem;
	}
	.pt-sm-4, .py-sm-4 {
		padding-top: 1.5rem;
	}
	.pr-sm-4, .px-sm-4 {
		padding-right: 1.5rem;
	}
	.pb-sm-4, .py-sm-4 {
		padding-bottom: 1.5rem;
	}
	.pl-sm-4, .px-sm-4 {
		padding-left: 1.5rem;
	}
	.p-sm-5 {
		padding: 3rem;
	}
	.pt-sm-5, .py-sm-5 {
		padding-top: 3rem;
	}
	.pr-sm-5, .px-sm-5 {
		padding-right: 3rem;
	}
	.pb-sm-5, .py-sm-5 {
		padding-bottom: 3rem;
	}
	.pl-sm-5, .px-sm-5 {
		padding-left: 3rem;
	}
	.m-sm-auto {
		margin: auto;
	}
	.mt-sm-auto, .my-sm-auto {
		margin-top: auto;
	}
	.mr-sm-auto, .mx-sm-auto {
		margin-right: auto;
	}
	.mb-sm-auto, .my-sm-auto {
		margin-bottom: auto;
	}
	.ml-sm-auto, .mx-sm-auto {
		margin-left: auto;
	}
}

@media (min-width: 768px) {
	.m-md-0 {
		margin: 0;
	}
	.mt-md-0, .my-md-0 {
		margin-top: 0;
	}
	.mr-md-0, .mx-md-0 {
		margin-right: 0;
	}
	.mb-md-0, .my-md-0 {
		margin-bottom: 0;
	}
	.ml-md-0, .mx-md-0 {
		margin-left: 0;
	}
	.m-md-1 {
		margin: 0.25rem;
	}
	.mt-md-1, .my-md-1 {
		margin-top: 0.25rem;
	}
	.mr-md-1, .mx-md-1 {
		margin-right: 0.25rem;
	}
	.mb-md-1, .my-md-1 {
		margin-bottom: 0.25rem;
	}
	.ml-md-1, .mx-md-1 {
		margin-left: 0.25rem;
	}
	.m-md-2 {
		margin: 0.5rem;
	}
	.mt-md-2, .my-md-2 {
		margin-top: 0.5rem;
	}
	.mr-md-2, .mx-md-2 {
		margin-right: 0.5rem;
	}
	.mb-md-2, .my-md-2 {
		margin-bottom: 0.5rem;
	}
	.ml-md-2, .mx-md-2 {
		margin-left: 0.5rem;
	}
	.m-md-3 {
		margin: 1rem;
	}
	.mt-md-3, .my-md-3 {
		margin-top: 1rem;
	}
	.mr-md-3, .mx-md-3 {
		margin-right: 1rem;
	}
	.mb-md-3, .my-md-3 {
		margin-bottom: 1rem;
	}
	.ml-md-3, .mx-md-3 {
		margin-left: 1rem;
	}
	.m-md-4 {
		margin: 1.5rem;
	}
	.mt-md-4, .my-md-4 {
		margin-top: 1.5rem;
	}
	.mr-md-4, .mx-md-4 {
		margin-right: 1.5rem;
	}
	.mb-md-4, .my-md-4 {
		margin-bottom: 1.5rem;
	}
	.ml-md-4, .mx-md-4 {
		margin-left: 1.5rem;
	}
	.m-md-5 {
		margin: 3rem;
	}
	.mt-md-5, .my-md-5 {
		margin-top: 3rem;
	}
	.mr-md-5, .mx-md-5 {
		margin-right: 3rem;
	}
	.mb-md-5, .my-md-5 {
		margin-bottom: 3rem;
	}
	.ml-md-5, .mx-md-5 {
		margin-left: 3rem;
	}
	.p-md-0 {
		padding: 0;
	}
	.pt-md-0, .py-md-0 {
		padding-top: 0;
	}
	.pr-md-0, .px-md-0 {
		padding-right: 0;
	}
	.pb-md-0, .py-md-0 {
		padding-bottom: 0;
	}
	.pl-md-0, .px-md-0 {
		padding-left: 0;
	}
	.p-md-1 {
		padding: 0.25rem;
	}
	.pt-md-1, .py-md-1 {
		padding-top: 0.25rem;
	}
	.pr-md-1, .px-md-1 {
		padding-right: 0.25rem;
	}
	.pb-md-1, .py-md-1 {
		padding-bottom: 0.25rem;
	}
	.pl-md-1, .px-md-1 {
		padding-left: 0.25rem;
	}
	.p-md-2 {
		padding: 0.5rem;
	}
	.pt-md-2, .py-md-2 {
		padding-top: 0.5rem;
	}
	.pr-md-2, .px-md-2 {
		padding-right: 0.5rem;
	}
	.pb-md-2, .py-md-2 {
		padding-bottom: 0.5rem;
	}
	.pl-md-2, .px-md-2 {
		padding-left: 0.5rem;
	}
	.p-md-3 {
		padding: 1rem;
	}
	.pt-md-3, .py-md-3 {
		padding-top: 1rem;
	}
	.pr-md-3, .px-md-3 {
		padding-right: 1rem;
	}
	.pb-md-3, .py-md-3 {
		padding-bottom: 1rem;
	}
	.pl-md-3, .px-md-3 {
		padding-left: 1rem;
	}
	.p-md-4 {
		padding: 1.5rem;
	}
	.pt-md-4, .py-md-4 {
		padding-top: 1.5rem;
	}
	.pr-md-4, .px-md-4 {
		padding-right: 1.5rem;
	}
	.pb-md-4, .py-md-4 {
		padding-bottom: 1.5rem;
	}
	.pl-md-4, .px-md-4 {
		padding-left: 1.5rem;
	}
	.p-md-5 {
		padding: 3rem;
	}
	.pt-md-5, .py-md-5 {
		padding-top: 3rem;
	}
	.pr-md-5, .px-md-5 {
		padding-right: 3rem;
	}
	.pb-md-5, .py-md-5 {
		padding-bottom: 3rem;
	}
	.pl-md-5, .px-md-5 {
		padding-left: 3rem;
	}
	.m-md-auto {
		margin: auto;
	}
	.mt-md-auto, .my-md-auto {
		margin-top: auto;
	}
	.mr-md-auto, .mx-md-auto {
		margin-right: auto;
	}
	.mb-md-auto, .my-md-auto {
		margin-bottom: auto;
	}
	.ml-md-auto, .mx-md-auto {
		margin-left: auto;
	}
}

@media (min-width: 992px) {
	.mr-lg-5, .mx-lg-5 {
		margin-right: 3rem;
	}
	.ml-lg-5, .mx-lg-5 {
		margin-left: 3rem;
	}
	.pt-lg-0 {
		padding-top: 0;
	}
}

.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

.text-uppercase {
	text-transform: uppercase;
}

@media (min-width: 576px) {
	.text-sm-right {
		text-align: right;
	}
}